import { Dispatch } from 'redux'

import type {
  ChangePasswordBody,
  GetLocationDetailsParams,
  GetNationalitiesParams,
  UpdateUserContactsBody,
  GetVisaIssuePlacesParams,
  UpdateUserPersonalInfoBody,
  GetReplaceableVisaParams,
  GetSkillsParams,
  CancelVisaParams,
  DownloadHistoryDetailsParams,
  UpdateLaborsBody,
  MakeRefundSADADParams,
  MakeRefundPayFortParams,
  GetBillsParams,
  GetRejectionReasonsListParams,
  SubmitWataniSurveyParams,
  GetOccupationDescriptionsParams,
  SurveySubmitBody,
  GetRunawaysParams,
  ReportRunawaysBody,
  CheckFeesExemptionParams,
  UploadDisabilityProofBody,
  SubmitFeesExemptionBody,
  UpdateNationalAddressParams,
  SetNewPrimaryAddressParams,
  GetArrivalCitiesParams,
  getInvoiceFileParams,
  VerifyAbsherForUpdatingMobileBody,
  SendOtpForUpdatingMobileBody,
  VerifyOtpForUpdatingMobileBody,
  VerifyAgentBody,
  CreateAgentBody,
} from 'api/evisaAPI/evisaAPI'
import { ENABLE_PREVIOUSLY_UPLOADED } from 'common/envConstants'

import Actions from '../../actions'

export const getNationalities = (params: GetNationalitiesParams = {}) => ({
  type: Actions.GET_NATIONALITIES,
  payload: { params },
})

export const getOccupations = () => ({
  type: Actions.GET_OCCUPATIONS,
})

export const getLaborOffices = () => ({
  type: Actions.GET_LABOR_OFFICES,
})

export const sendAbsherForUpdatingMobile = () => ({
  type: Actions.SEND_ABSHER_FOR_UPDATE_MOBILE,
})
export const verifyAbsherForUpdatingMobile = (body: VerifyAbsherForUpdatingMobileBody) => ({
  type: Actions.VERIFY_ABSHER_FOR_UPDATE_MOBILE,
  payload: { params: body },
})
export const verifyAgent = (body: VerifyAgentBody) => ({
  type: Actions.VERIFY_AGENT,
  payload: { params: body },
})
export const removeAgent = () => ({
  type: Actions.REMOVE_AGENT,
})
export const createAgent = (body: CreateAgentBody) => ({
  type: Actions.CREATE_AGENT,
  payload: { params: body },
})
export const sendOtpForUpdatingMobile = (body: SendOtpForUpdatingMobileBody) => ({
  type: Actions.SEND_OTP_FOR_UPDATE_MOBILE,
  payload: { params: body },
})
export const verifyOtpForUpdatingMobile = (body: VerifyOtpForUpdatingMobileBody) => ({
  type: Actions.VERIFY_OTP_FOR_UPDATE_MOBILE,
  payload: { params: body },
})

export const getArrivalCities = (params?: GetArrivalCitiesParams) => ({
  type: Actions.GET_ARRIVAL_CITIES,
  payload: { params },
})

export const getDefaultArrivalCity = () => ({
  type: Actions.GET_DEFAULT_ARRIVAL_CITY,
})

export const getWorkersList = () => ({
  type: Actions.GET_WORKERS,
})

export const getEligibleWorkers = () => ({
  type: Actions.GET_ELIGIBLE_WORKERS,
})

export const getLocationDetails = (params: GetLocationDetailsParams) => ({
  type: Actions.GET_LOCATION_DETAILS,
  payload: { params },
})

export const saveUserData = (body: UpdateUserPersonalInfoBody & UpdateUserContactsBody) => ({
  type: Actions.SAVE_USER_DATA,
  payload: { params: body },
})

export const updatePersonalDetails = (body: UpdateUserPersonalInfoBody) => ({
  type: Actions.UPDATE_PERSONAL_INFO,
  payload: { params: body },
})

export const updateUserContacts = (body: UpdateUserContactsBody) => ({
  type: Actions.UPDATE_USER_CONTACTS,
  payload: { params: body },
})

export const reportRunawayLaborer = (body: ReportRunawaysBody) => ({
  type: Actions.REPORT_RUNAWAY_LABORER,
  payload: { params: body },
})

export const cancelReportRunawayLaborer = (body: ReportRunawaysBody) => ({
  type: Actions.CANCEL_REPORT_RUNAWAY_LABORER,
  payload: { params: body },
})

export const changePassword = (body: ChangePasswordBody) => ({
  type: Actions.CHANGE_PASSWORD,
  payload: { params: body },
})

export const getVisaIssuePlaces = (params: GetVisaIssuePlacesParams = {}) => ({
  type: Actions.GET_VISA_ISSUE_PLACES,
  payload: { params },
})

export const getReplaceableVisa = (params: GetReplaceableVisaParams) => ({
  type: Actions.GET_REPLACEABLE_VISA,
  payload: { params },
})

export const getSkills = (params: GetSkillsParams) => ({
  type: Actions.GET_SKILLS,
  payload: { params },
})

export const getRejectionReasonsList = (params: GetRejectionReasonsListParams = {}) => ({
  type: Actions.GET_REJECTION_REASONS_LIST,
  payload: { params },
})

export const cancelVisa = (params: CancelVisaParams) => ({
  type: Actions.CANCEL_VISA,
  payload: { params },
})

export const makeRefundSADAD = (params: MakeRefundSADADParams) => ({
  type: Actions.MAKE_REFUND_SADAD,
  payload: { params },
})

export const makeRefundPayFort = (params: MakeRefundPayFortParams) => ({
  type: Actions.MAKE_REFUND_PAY_FORT,
  payload: { params },
})

export const getRefundableBills = (params: GetBillsParams) => ({
  type: Actions.GET_REFUNDABLE_BILLS,
  payload: { params },
})

export const getRunaways = (params: GetRunawaysParams) => ({
  type: Actions.GET_RUNAWAYS,
  payload: { params },
})

export const getEligibleLaborers = () => ({
  type: Actions.GET_ELIGIBLE_LABORERS,
})

export const checkFeesExemption = (params: CheckFeesExemptionParams) => ({
  type: Actions.CHECK_FEES_EXEMPTION,
  payload: { params },
})

export const getVisaInvoiceFile = (params: getInvoiceFileParams) => ({
  type: Actions.GET_VISA_INVOICE_FILE,
  payload: { params },
})

export const submitFeesExemption = (body: SubmitFeesExemptionBody) => ({
  type: Actions.SUBMIT_FEES_EXEMPTION,
  payload: { params: body },
})

export type UpdateFilesProgressAction = {
  type: Actions.UPDATE_FILES_PROGRESS
  payload: {
    fileName: string
    progress: number
    timeLeft: number
  }
}

export const uploadDisabilityProof =
  (body: UploadDisabilityProofBody, useOCR = false) =>
  (dispatch: Dispatch) => {
    const timeStart = new Date().getTime()
    return dispatch({
      type: Actions.UPLOAD_DISABILITY_PROOF,
      payload: {
        params: body,
        useOCR,
        config: {
          onUploadProgress: (event: ProgressEvent) => {
            const { loaded, total } = event
            const timeElapsed = new Date().getTime() - timeStart
            const uploadingSpeed = loaded / timeElapsed
            const bytesLeft = total - loaded
            const timeLeft = bytesLeft / uploadingSpeed

            dispatch({
              type: Actions.UPDATE_FILES_PROGRESS,
              payload: {
                fileName: body.file.name,
                progress: ((loaded * 100) / total).toFixed(1),
                timeLeft: timeLeft.toFixed(1),
              },
            })
          },
        },
      },
    })
  }

export type RemoveDisabilityFileAction = {
  type: Actions.REMOVE_DISABILITY_PROOF_FILE
  payload: {
    fileName: string
  }
}

export const removeDisabilityFile = (fileName: string): RemoveDisabilityFileAction => ({
  type: Actions.REMOVE_DISABILITY_PROOF_FILE,
  payload: {
    fileName,
  },
})

export const checkOutdatedIBAN = () => ({
  type: Actions.CHECK_OUTDATED_IBAN,
})

export const getDownloadHistory = () =>
  ENABLE_PREVIOUSLY_UPLOADED
    ? {
        type: Actions.GET_DOWNLOAD_HISTORY,
      }
    : { type: Actions.DO_NOTHING }

export const getDownloadHistoryDetails = (params: DownloadHistoryDetailsParams) => ({
  type: Actions.GET_DOWNLOAD_HISTORY_DETAILS,
  payload: { params },
})

export const getLabors = () => ({
  type: Actions.GET_LABORS,
})

export const updateLabors = (body: UpdateLaborsBody) => ({
  type: Actions.UPDATE_LABORS,
  payload: { params: body },
})

export const submitWataniSurvey = (params: SubmitWataniSurveyParams) => ({
  type: Actions.SUBMIT_WATANI_SURVEY,
  payload: { params },
})

export const surveySubmit = (body: SurveySubmitBody) => ({
  type: Actions.SUBMIT_SURVEY,
  payload: { params: body },
})

export const getOccupationDescriptions = (params: GetOccupationDescriptionsParams = {}) => ({
  type: Actions.GET_OCCUPATION_DESCRIPTIONS,
  payload: { params },
})

export const removeFromPilotList = () => ({
  type: Actions.REMOVE_FROM_PILOT_LIST,
})

export const updateNationalAddress = (params: UpdateNationalAddressParams = {}) => ({
  type: Actions.UPDATE_NATIONAL_ADDRESS,
  payload: { params },
})

export const setNewPrimaryAddress = (params: SetNewPrimaryAddressParams) => ({
  type: Actions.SET_NEW_PRIMARY_ADDRESS,
  payload: { params },
})
