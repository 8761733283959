import { GetTicketListResponse, TicketTruncatedFromBE, TicketFromBE, ComplaintActionItemFromBE } from 'api/jiraAPI'
import {
  COMPLAINT_PRO_FILE_PREFIX,
  COMPLAINT_TICKET_OTHER_CATEGORY,
  MISSING_ATTACHMENT_PREFIX,
} from 'common/constants'
import {
  ComplaintsAuthors,
  ComplaintStatuses,
  ComplaintTicketStatus,
  ComplaintTicketCountDownStatus,
  ComplaintTicketClosureCategory,
} from 'common/types/commonTypes'

export type TicketTruncated = {
  id: string
  key: string
  fields: {
    contractId: string
    category: {
      id: string
    }
    status: {
      id: string
      name: ComplaintTicketStatus
    }
    created: {
      date: string
    }
    countdown: {
      remaining: number
      status: ComplaintTicketCountDownStatus
      showNoAnswer: boolean
    }
  }
}

const convertTruncatedComplaintTicket = (ticketFromBe: TicketTruncatedFromBE): TicketTruncated => ({
  id: ticketFromBe.id,
  key: ticketFromBe.key,
  fields: {
    contractId: ticketFromBe.fields.contract_id,
    status: {
      id: ticketFromBe.fields.status.id,
      name: ticketFromBe.fields.status.name,
    },
    category: {
      id: ticketFromBe.fields?.category?.id || COMPLAINT_TICKET_OTHER_CATEGORY,
    },
    created: {
      date: ticketFromBe.fields.created.date,
    },
    countdown: {
      remaining: ticketFromBe.fields.countdown.remaining,
      status: ticketFromBe.fields.countdown.status,
      showNoAnswer: !!ticketFromBe.fields.countdown.show_no_answer,
    },
  },
})

export const convertTruncatedComplaintTicketList = (resp: GetTicketListResponse) =>
  resp.issues.map(convertTruncatedComplaintTicket)

export type TicketAttachment = {
  filename: string
  id: string
  mimeType: string
}

export type ComplaintActionItem = {
  attachments: Array<TicketAttachment>
  created: string
  toStatusId: string
  fromStatusId: string
  response: string
}

export type Ticket = {
  id: string
  key: string
  isNeedCCItem?: boolean
  fields: {
    proNowSuspended: boolean
    employerDidNotRespond: boolean
    contractId: string
    employerAttachments: Array<TicketAttachment>
    proAttachments: Array<TicketAttachment>
    proProofAttachment?: TicketAttachment
    workflow: Array<ComplaintActionItem>
    proId: string
    description: string
    lastStepStatus: ComplaintStatuses
    proResponse: {
      value: string
      created: string
    } | null
    ccResponse: {
      value: string
      created: string
    } | null
    mlsdResponse: {
      value: string
      created: string
    } | null
    mucResolutionCategory?: {
      id: string
    }
    proSecondSolution: Array<string>
    category: {
      id: string
    }
    status: {
      id: string
      name: ComplaintTicketStatus
    }
    created: {
      date: string
    }
    countdown: {
      remaining: number
      status: ComplaintTicketCountDownStatus
      showNoAnswer: boolean
    }
    missingAttachmentsCcNotes?: string
    missingAttachmentsMhrsdNotes?: string
    reporter: { displayName: string }
    verifyProAttach: boolean
    closureCategory: ComplaintTicketClosureCategory
  }
}

export const convertComplaintTicket = (ticketFromBe: TicketFromBE): Ticket => {
  const skipStep = (step: ComplaintActionItemFromBE) =>
    !(
      step.from_status_id === ComplaintStatuses.MHRSD_SOLUTION_PROVIDED ||
      step.to_status_id === ComplaintStatuses.WAITING_FOR_MHRSD_VERIFICATION ||
      step.to_status_id === ComplaintStatuses.CC_SOLUTION_APPROVAL ||
      step.to_status_id === ComplaintStatuses.MLSD_SOLUTION_APPROVAL ||
      step.to_status_id === ComplaintStatuses.PENDING_EMPLOYER_VERIFICATION ||
      (step.to_status_id === ComplaintStatuses.WAITING_FOR_HALOUL &&
        step.from_status_id !== ComplaintStatuses.PRO_SOLUTION_ACCEPTANCE) ||
      step.to_status_id === ComplaintStatuses.WAITING_FOR_TAKAMOL_VERIFICATION ||
      step.to_status_id === ComplaintStatuses.VALIDATE_SUSPNDED_PROS ||
      (step.from_status_id === ComplaintStatuses.VALIDATE_SUSPNDED_PROS &&
        step.to_status_id === ComplaintStatuses.WAITING_FOR_CC) ||
      (step.from_status_id === ComplaintStatuses.CC_SOLUTION_APPROVAL &&
        step.to_status_id === ComplaintStatuses.WAITING_FOR_CC) ||
      (step.from_status_id === ComplaintStatuses.MLSD_SOLUTION_APPROVAL &&
        step.to_status_id === ComplaintStatuses.WAITING_FOR_MLSD_L2)
    )
  const convertAttachment = (att: Required<TicketFromBE['fields']>['attachment'][0]) => ({
    id: att.id,
    filename: att.filename.replace(/(_[0-9]*_)/, ''),
    mimeType: att.mimeType,
  })

  const proProofAttachments = ticketFromBe.fields.attachment
    .filter(
      (att) =>
        att.author.name === ComplaintsAuthors.PRO &&
        COMPLAINT_PRO_FILE_PREFIX.some((prefix) => att.filename.indexOf(prefix) !== -1),
    )
    .map(convertAttachment)

  const workflow = ticketFromBe.fields.workflow
  const lastWorfflowItem = workflow[workflow.length - 1] ? workflow[workflow.length - 1].to_status_id : null
  const isNeedCCItem =
    !!lastWorfflowItem &&
    (lastWorfflowItem === ComplaintStatuses.WAITING_FOR_CC ||
      lastWorfflowItem === ComplaintStatuses.WAITING_FOR_HALOUL ||
      lastWorfflowItem === ComplaintStatuses.CC_SOLUTION_APPROVAL ||
      lastWorfflowItem === ComplaintStatuses.WAITING_FOR_TAKAMOL_VERIFICATION)

  return {
    id: ticketFromBe.id,
    key: ticketFromBe.key,
    isNeedCCItem: isNeedCCItem,
    fields: {
      workflow: workflow
        .reverse()
        .filter(
          (item) => Object.values(ComplaintStatuses).includes(item.to_status_id as ComplaintStatuses) && skipStep(item),
        )
        .map((actionItem) => ({
          created: actionItem.created,
          attachments: actionItem.attachments,
          toStatusId: actionItem.to_status_id,
          fromStatusId: actionItem.from_status_id,
          response: actionItem.response,
        }))
        .concat([
          {
            created: ticketFromBe.fields.created.date,
            toStatusId: ComplaintStatuses.COMPLAINT_INITIAL_STATE,
            fromStatusId: '',
            attachments: [],
            response: '',
          },
        ]),
      proNowSuspended: !!ticketFromBe.fields.pro_now_suspended,
      lastStepStatus: ticketFromBe.fields.workflow[0]?.to_status_id as ComplaintStatuses,
      employerDidNotRespond: !!ticketFromBe.fields.employer_did_not_respond,
      contractId: ticketFromBe.fields.contract_id,
      proId: ticketFromBe.fields.pro_id,
      description: ticketFromBe.fields.description,
      proResponse: ticketFromBe.fields.pro_response,
      ccResponse: ticketFromBe.fields.cc_response,
      mlsdResponse: ticketFromBe.fields.mlsd_response,
      proSecondSolution: ticketFromBe.fields.second_pro_solution || [],
      employerAttachments: ticketFromBe.fields.attachment
        .filter(
          (att) =>
            att.author.name === ComplaintsAuthors.EMPLOYER && att.filename.indexOf(MISSING_ATTACHMENT_PREFIX) === -1,
        )
        .map(convertAttachment),
      proAttachments: ticketFromBe.fields.attachment
        .filter((att) => att.author.name === ComplaintsAuthors.PRO)
        .map(convertAttachment),
      proProofAttachment: proProofAttachments[proProofAttachments.length - 1], // We should show only latest one (they came from backend in order from oldest to latest)
      status: {
        id: ticketFromBe.fields.status.id,
        name: ticketFromBe.fields.status.name,
      },
      mucResolutionCategory: ticketFromBe.fields.muc_resolution_category,
      category: {
        id: ticketFromBe.fields?.category?.id || COMPLAINT_TICKET_OTHER_CATEGORY,
      },
      created: {
        date: ticketFromBe.fields.created.date,
      },
      countdown: {
        remaining: ticketFromBe.fields.countdown.remaining,
        status: ticketFromBe.fields.countdown.status,
        showNoAnswer: !!ticketFromBe.fields.countdown.show_no_answer,
      },
      missingAttachmentsCcNotes: ticketFromBe.fields.missing_attachments_cc_notes,
      missingAttachmentsMhrsdNotes: ticketFromBe.fields.missing_attachments_mhrsd_notes,
      reporter: ticketFromBe.fields.reporter,
      verifyProAttach: ticketFromBe.fields.verify_pro_attach,
      closureCategory: ticketFromBe.fields.closure_category,
    },
  }
}
