import React, { FunctionComponent } from 'react'
import * as Sentry from '@sentry/browser'
import { useTranslation } from 'react-i18next'

import { TranslationNamespace } from 'common/utils/translation'
import Typography from 'ui-components/typography/Typography'
import Button from 'ui-components/buttons/Button/Button'
import TransUtil from 'ui-components/transUtil/TransUtil'

import { ReactComponent as OfflineIcon } from 'images/no-connection-icon.svg'
import WarnIcon from 'images/warning-icon.svg'

import styles from './ErrorContent.module.scss'

type ErrorContentProps = {
  title: string
  description: string
  type?: 'warning' | 'offline'
  withEventId?: boolean
}

const ErrorContent: FunctionComponent<ErrorContentProps> = ({
  title,
  description,
  type = 'warning',
  withEventId = true,
}) => {
  const { t } = useTranslation(TranslationNamespace.errorPage)
  const eventId = Sentry.lastEventId()
  return (
    <div className={styles.root}>
      <div className={styles.rowContainer}>
        {type === 'warning' && <img src={WarnIcon} className={styles.icon} alt={t('warning')} />}
        {type === 'offline' && <OfflineIcon className={styles.offlineIcon} />}
      </div>
      <Typography appearance='title' className={styles.title}>
        {t(title)}
      </Typography>
      <div>
        <div className={styles.description}>{t(description)}</div>
        {withEventId && eventId && (
          <div className={styles.referenceNumber}>
            <TransUtil t={t} tKey='referenceNumber' values={{ errorSentryId: eventId }} />
          </div>
        )}
      </div>
      <div className={styles.rowContainer}>
        <Button onClick={() => window.location.reload()}>{t('refresh')}</Button>
      </div>
    </div>
  )
}

export default ErrorContent
