import type { AxiosRequestConfig } from 'axios'

import { callAPI, evisaAPI, evisaAPIWithCredentials } from 'api/axios'
import type {
  NationalityFromBE,
  OccupationFromBE,
  AlternativeObjectWithTranslationFromBE,
  LaborFromBE,
  LaborOfficeFromBE,
  WataniSurveyFromBE,
  UpdatedLaborFromBE,
  ObjectWithTranslationFromBE,
} from 'common/types/transformedRespTypes'
import { Answer, BillRequestType, GeneralFailResponse, ValidationErrors } from 'common/types/commonTypes'
import { EVISA_BASE_API_URL } from 'common/envConstants'
import authManager from 'common/utils/auth/authManager'

export type GetNationalitiesParams = {
  online?: boolean
  occupation?: number | string
  dl?: boolean
  vip?: number
  farmerVisa?: number
  idNumber?: number
}

export type GetNationalitiesResponse = {
  nationalities: Array<NationalityFromBE>
}

export const getPreviewURLForUploadedFile = (id: string) =>
  `${EVISA_BASE_API_URL}/app/user/uploads/download/${id}?X-AUTH-TOKEN=${authManager.getEvisaToken()}`

const getNationalities = (
  { online, occupation, dl, vip, farmerVisa, idNumber }: GetNationalitiesParams = {},
  config: AxiosRequestConfig,
) =>
  evisaAPI.get('/api/nationalities', {
    params: { online, occupation, dl, vip, farmer_visa: farmerVisa, id_number: idNumber },
    ...config,
  })

export type GetOccupationsResponse = {
  occupations: Array<OccupationFromBE>
}

const getOccupations = (params = {}, config: AxiosRequestConfig) =>
  evisaAPI.get<GetOccupationsResponse>('/api/occupations', { ...params, ...config })

export type GetLaborOfficesResponse = {
  offices: Array<LaborOfficeFromBE>
}

const getLaborOffices = (params = {}, config: AxiosRequestConfig) =>
  evisaAPI.get<GetLaborOfficesResponse>('/api/labor_offices', { ...params, ...config })

export type GetArrivalCitiesParams = {
  airportOnly?: boolean
}

export type GetArrivalCitiesResponse = {
  cities: Array<{
    id: string
    label_ar: string
    label_en: string
  }>
}

const getArrivalCities = (params: GetArrivalCitiesParams = { airportOnly: true }, config: AxiosRequestConfig) =>
  evisaAPI.get<GetArrivalCitiesResponse>('/api/cities', {
    ...(params.airportOnly ? { params: { airport_only: params.airportOnly } } : {}),
    ...config,
  })

export type NationalAddressResponseType = {
  id: string
  is_primary: string
  buildingNumber: string
  streetName: string
  streetNameEn: string
  additionalNumber: string
  districtArea: string
  districtAreaEn: string
  cityName: string
  cityNameEn: string
  zipcode: string
  detailed: string
  updated_at: string
}

export type GetUserProfileResponse = {
  email: string
  insurance_enabled: boolean
  id_number: number
  mobile: string
  name: string
  nic: {
    marital_status: string
    marital_status_en: string
    nationality: string
    birth_date: string
    gender: string
    first_name: string
    first_name_en: string
    last_name: string
    last_name_en: string
    father_name: string
    father_name_en: string
    grandfather_name: string
    grandfather_name_en: string
  }
  personal_details: {
    address: string
    average_income: number
    coordinates: string
    domestic_laborers: number
    children_under_twelve_count: number
    iban_owner: string
    iban: string
    id: number
    user_id: number
    family_size: number
    house_type_id: number
    job: string
    route: string
    sublocality: string
    street_number: string
    postal_code: string
    postal_code_suffix: string
    is_outdated_iban: number
  } | null
  premium_agent: {
    id_number: number
    email: string
    mobile: number
    name: string
  } | null
  relation_manager: {
    uuid: string
    landline_number: number
    email: string
    mobile_number: number
    name: string
    authorized: string
  } | null
  secondary_mobile?: string
  username?: number
  show_current_balance_and_refund_request: boolean
  show_notice_balance_and_refund_request: boolean
  national_address?: Array<NationalAddressResponseType>
  user_title: {
    title: {
      ar: string
      en: string
    }
    description: {
      ar: string
    }
  } | null
}

const getUserProfile = (params = {}, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetUserProfileResponse>('/app/user/info', { ...params, ...config })

export type GetDefaultArrivalCityResponse = {
  city: {
    id: string
    label: string
    label_en: string
    region_id: string
  } | null
}

const getDefaultArrivalCity = (params = {}, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetDefaultArrivalCityResponse>('/app/geolocation/city', { params, ...config })

export type GetWorkersListParams = {}

export type WorkerFromBE = {
  Id: string
  Nationality: string
  Occupation: { Code: string; Name: string }
  Status: string
  Sex: string
  entryDate: string
  exitDate: string
}

export type WorkerInfoFromBE = {
  id: string
  name: string
  nationality: ObjectWithTranslationFromBE & { abbreviation: string }
  occupation: ObjectWithTranslationFromBE
}

export type VisaInfoFromBE = {
  VisaNo: string
  BorderNo: string
  VisaIssueDate: string
  VisaStatus: string
  nationality: ObjectWithTranslationFromBE
  occupation: ObjectWithTranslationFromBE
}

export type FarmerWorkerFromBE = {
  BorderNumber: string
  IdNumber: string
  Nationality: {
    NameLocal: string
    Code: number
  }
  Job: {
    NameLocal: string
    Code: number
  }
  Gender: {
    NameLocal: string
    Code: string
  }
  Status: {
    NameLocal: string
    Code: number
  }
}

export type GetVisaResponse = {
  BorderNo: string
  Nationality: string
  Occupation: string
  VisaIssueDate: string
  VisaNo: string
  VisaStatus: string
}

export type GetWorkersListResponse = {
  workers: Array<WorkerInfoFromBE>
  farmerWorkers: Array<WorkerInfoFromBE>
  visas: Array<VisaInfoFromBE>
}

const getWorkersList = (params: GetWorkersListParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get('/app/user/info_workers', { params, ...config })

export type GetEligibleWorkersParams = {}
export type GetEligibleWorkersResponse = Array<WorkerFromBE>

const getEligibleWorkers = (params: GetWorkersListParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get('/app/replaced_visas/eligibleWorkers', { params, ...config })

export type GetLocationDetailsParams = {
  lng: number
  lat: number
}

export type LocationItem = {
  types: Array<string>
  long_name: string
}

export type GetLocationDetailsResponse = {
  results: Array<{
    address_components: Array<LocationItem>
    formatted_address: string
  }>
}

const getLocationDetails = (params: GetLocationDetailsParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetLocationDetailsResponse>('/api/geolocation', { params, ...config })

export type UpdateUserPersonalInfoBody = {
  address?: string
  averageIncome: string
  childrenUnderTwelveCount: string
  coordinates?: string
  domesticLaborers: string
  familySize: string
  houseTypeId: string
  iban: string
  ibanOwner: string
  job: string
  locality?: string
  postalCode?: string
  postalCodeSuffix?: string
  route?: string
  streetNumber?: string
  sublocality?: string
  primaryAddress?: string
}

export type UpdateUserPersonalInfoFailResponse = ValidationErrors

const updateUserPersonalInfo = (body: UpdateUserPersonalInfoBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<{}>(
    '/app/user/details/info',
    {
      address: body.address,
      average_income: body.averageIncome,
      children_under_twelve_count: body.childrenUnderTwelveCount,
      coordinates: body.coordinates,
      domestic_laborers: body.domesticLaborers,
      family_size: body.familySize,
      house_type_id: body.houseTypeId,
      iban: body.iban,
      iban_owner: body.ibanOwner,
      job: body.job,
      locality: body.locality,
      postal_code: body.postalCode,
      postal_code_suffix: body.postalCodeSuffix,
      route: body.route,
      street_number: body.streetNumber,
      sublocality: body.sublocality,
      primary_address: body.primaryAddress || '',
      platform_source: 'online',
    },
    config,
  )

export type UpdateUserContactsBody = {
  email: string
  idNumber: number
  secondaryMobile: string
}

export type UpdateUserContactsFailResponse = ValidationErrors

const updateUserContacts = (body: UpdateUserContactsBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<{}>(
    '/app/user/details/contacts',
    {
      id_number: body.idNumber,
      email: body.email,
      secondary_mobile: body.secondaryMobile,
    },
    config,
  )

export type ChangePasswordBody = {
  oldPassword: string
  newPassword: string
}

const changePassword = (body: ChangePasswordBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<{}>(
    '/app/user/details/password',
    {
      old_password: body.oldPassword,
      new_password: body.newPassword,
    },
    config,
  )

export type GetVisaIssuePlacesParams = {
  nationality?: number
}

export type GetVisaIssuePlacesResponse = {
  visaIssuePlaces: Array<{
    id: number
    label: string
    label_en: string
  }>
}

export type VerifyAgentBody = {
  idNumber: string
}

export type VerifyAgentResponse = {
  fullName: string
  mobile: string
}

const verifyAgent = ({ idNumber }: VerifyAgentBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post('/app/premium/agent/verify', { id_number: idNumber }, config)

export type CreateAgentBody = {
  idNumber: string
  code: string
}

const createAgent = ({ idNumber, code }: CreateAgentBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post('/app/premium/agent/create', { id_number: idNumber, code }, config)

const removeAgent = () => evisaAPIWithCredentials.post('/app/premium/agent/delete')

const getVisaIssuePlaces = (params: GetVisaIssuePlacesParams = {}, config: AxiosRequestConfig) =>
  evisaAPI.get<GetVisaIssuePlacesResponse>('/api/visa_issue_places', { params, ...config })

export type GetReplaceableVisaParams = {
  workerId: number | string
}

export type GetReplaceableVisaResponse = {
  visaId: string
  occupation: OccupationFromBE
}

const getReplaceableVisa = ({ workerId = '' }: GetReplaceableVisaParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetReplaceableVisaResponse>(`/app/replaced_visas/replaceableVisa/${workerId}`, config)

export type GetSkillsParams = {
  occupationId: number
  contractOnline?: number
}

export type GetSkillsResponse = {
  skills: Array<AlternativeObjectWithTranslationFromBE>
}

const getSkills = ({ contractOnline = 1, occupationId }: GetSkillsParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get('/api/skills', {
    params: { contract_online: contractOnline, 'occupations[]': occupationId },
    ...config,
  })

export type GetRejectionReasonsListParams = {
  isFarmer?: 1
}

export type RejectionReasonsListResponse = AlternativeObjectWithTranslationFromBE[]

const getRejectionReasonsList = (params: GetRejectionReasonsListParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get('/app/user/details/reasons_cancel_visa', {
    params: { is_farmer: params.isFarmer },
    ...config,
  })

export type CancelVisaParams = {
  reason: number // reason id (comes from getRejectionReasonsList)
  visa: number // visa id
}

export type CancelVisaResponse = {
  status: string
}

const cancelVisa = (params: CancelVisaParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post('app/user/details/cancel_visa', params, config)

export type MakeRefundSADADParams = {
  billId: string
  bank: string
  iban: string

  name: string
  nin: string
  reason: string
}

export type MakeRefundSADADResponse = {
  show_notice_balance: boolean
  show_notice_balance_and_refund_request: boolean
}
const makeRefundSADAD = (params: MakeRefundSADADParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post(`/app/billing/refund_sadad/${params.billId}`, params, config)

export type CheckOutdatedIBANResponse = {
  isHasOutdatediban: boolean
}

const checkOutdatedIBAN = (config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get('/app/user/is_outdated_iban', config)

export type DownloadHistoryResponse = Array<string>

const getDownloadHistory = (config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get('/app/user/uploads/download_history', config)

export type DownloadHistoryDetailsParams = {
  uploads: Array<string>
}

export type HistoryDetailsFromBE = {
  id: string
  original_file_name: string
  mime_type: string
  created_at: string
}

export type DownloadHistoryDetailsResponse = Array<HistoryDetailsFromBE>

const getDownloadHistoryDetails = (params: DownloadHistoryDetailsParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get('/app/user/uploads/details', {
    params,
    ...config,
  })
export type GetLaborsResponse = Array<LaborFromBE>

const getLabors = (params = {}, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get('/app/user/get_labors', config)

export type UpdateLaborsBody = {
  labors: Array<{
    labor_id: string
    salary: string
  }>
}

export type UpdateLaborsResponse = Array<UpdatedLaborFromBE>

const updateLabors = (body: UpdateLaborsBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post('/app/user/update_labors', body, config)

export type MakeRefundPayFortParams = {
  billId: string
}

export type MakeRefundPayFortResponse = {}

const makeRefundPayFort = (params: MakeRefundPayFortParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post(`/app/billing/refund_payfort/${params.billId}`, params, config)

export type BillFromBE = {
  account_number: string
  amount: string
  bill_number: string
  created_at: string
  expiry_date: string
  id: string
  payment_method: string
  refund_status: null
  refunded_at: null
  status: number
  request_type: BillRequestType
}

export type GetBillsParams = {
  refundable?: boolean
}
export type GetBillsResponse = Array<BillFromBE>

const getRefundableBills = (params: GetBillsParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get('app/bills', { ...config, params })

export type SubmitSurveyResponse = {
  message: string
}

const getRunaways = (params: GetRunawaysParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get('app/runaway_laborers/get_laborers ', { ...config })

export type GetRunawaysParams = {
  idNumber: number
}

export type RunawayStatus = 'Working' | 'Runner'

export type RunawayLaborFromBE = {
  id: string
  name: string
  nationality: NationalityFromBE
  occupation: OccupationFromBE
  pending_cancel_report: boolean
  pending_runner_report: boolean
  cancelable: boolean
  status: {
    Code: string
    Name: RunawayStatus
    NameLocal: string
  }
}

export type GetRunawaysResponse = { farmerWorkers: Array<RunawayLaborFromBE> }

export type ReportRunawaysBody = {
  id: string
}

const reportRunawayLaborer = (body: ReportRunawaysBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post('app/runaway_laborers/create_report', { laborer_iqama_number: body.id }, config)

const cancelReportRunawayLaborer = (body: ReportRunawaysBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post('app/runaway_laborers/cancel_report', { laborer_iqama_number: body.id }, config)

export type KeepAliveEvisaTokenResponse = {}

const keepAliveEvisaToken = (params = {}, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<KeepAliveEvisaTokenResponse>('/app/user/keepAlive', config)

export type GetWataniSurveyResponse = {
  questions: Array<WataniSurveyFromBE>
}

export type SubmitWataniSurveyParams = {
  answers: Array<Answer>
  requestId?: number | string
  surveyService: string
}

const submitWataniSurvey = (
  { answers, requestId, surveyService }: SubmitWataniSurveyParams,
  config: AxiosRequestConfig,
) =>
  evisaAPIWithCredentials.post(
    'app/surveys',
    {
      answers,
      request_id: requestId,
      survey_service: surveyService,
    },
    config,
  )

export type GetOccupationDescriptionsParams = {
  occupations?: number
}

export type GetOccupationDescriptionsResponse = {
  occupation_descriptions: Array<AlternativeObjectWithTranslationFromBE>
}

const getOccupationDescriptions = (params = {}, config: AxiosRequestConfig) =>
  evisaAPI.get<GetOccupationDescriptionsResponse>('api/occupation_descriptions', { params, ...config })

export type SurveySubmitBody =
  | {
      username: string
      result: boolean
    }
  | Record<string, number>

const surveySubmit = (body: SurveySubmitBody, config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<{}>('/api/fraud_survey/submit', { ...body })

const removeFromPilotList = (config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<{}>('/app/pilot/opt_out', config)

export type GetEligibleLaborersResponse = { laborers: string[] }

const getEligibleLaborers = (params = {}, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetEligibleLaborersResponse>('/app/fees_exemption/eligible_laborers', config)

export type CheckFeesExemptionParams = {
  laborerId: string
}

export type CheckFeesExemptionResponse = {
  laborers_count: number
  visas_count: number
}

const checkFeesExemption = (params: CheckFeesExemptionParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<CheckFeesExemptionResponse>(`/app/fees_exemption/laborer/${params.laborerId}`, config)

export type UploadDisabilityProofBody = {
  file: File
}

export type UploadDisabilityProofResponse = {
  userUpload: {
    file_path: string
    mime_type: string
    original_file_name: string
    created: string
    id: number
  }
}

const uploadDisabilityProof = (body: UploadDisabilityProofBody, config: AxiosRequestConfig) => {
  const data = new FormData()
  data.append('file', body.file)
  return evisaAPIWithCredentials.post<UploadDisabilityProofResponse>('/app/user/uploads/upload', data, config)
}

export type SubmitFeesExemptionBody = {
  laborerID: string
  laborerName: string
  documents: string
}

const submitFeesExemption = (body: SubmitFeesExemptionBody, config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<{}>(
    '/app/fees_exemption/submit',
    {
      laborer_iqama: body.laborerID,
      laborer_name: body.laborerName,
      documents: body.documents,
    },
    config,
  )

export type UpdateNationalAddressParams = {}

export type UpdateNationalAddressResponse = {
  addresses: Array<NationalAddressResponseType>
}

const updateNationalAddress = (params: UpdateNationalAddressParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<UpdateNationalAddressResponse>('app/user/details/update_national_address ', {}, config)

export type SetNewPrimaryAddressParams = {
  id: string
}

export type SetNewPrimaryAddressResponse = {
  detailed_address: Array<NationalAddressResponseType>
}

const setNewPrimaryAddress = ({ id }: SetNewPrimaryAddressParams, config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<SetNewPrimaryAddressResponse>(`app/user/details/set_primary_address/${id}`, config)

export type getInvoiceFileParams = {
  id: string
}

export type getInvoiceFileResponse = {
  bytes: string
  name: string
}

const getVisaInvoiceFile = ({ id }: getInvoiceFileParams, config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<getInvoiceFileResponse>(`app/billing/tbs/pdf/${id}`, config)

const sendAbsherForUpdatingMobile = (config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<getInvoiceFileResponse>('/app/user/update_mobile/send_absher', { resend: true }, config)

export type VerifyAbsherForUpdatingMobileBody = {
  code: string
}
const verifyAbsherForUpdatingMobile = ({ code }: VerifyAbsherForUpdatingMobileBody, config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<getInvoiceFileResponse>('/app/user/update_mobile/verify_absher', { code }, config)

export type SendOtpForUpdatingMobileBody = {
  mobile: string
}
const sendOtpForUpdatingMobile = ({ mobile }: SendOtpForUpdatingMobileBody, config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<getInvoiceFileResponse>(
    '/app/user/update_mobile/send_otp',
    { mobile, resend: true },
    config,
  )

export type VerifyOtpForUpdatingMobileBody = {
  mobile: string
  code: string
}
const verifyOtpForUpdatingMobile = ({ mobile, code }: VerifyOtpForUpdatingMobileBody, config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<getInvoiceFileResponse>('/app/user/update_mobile/verify_otp', { mobile, code }, config)

export default {
  getNationalities: callAPI<typeof getNationalities, GetNationalitiesResponse>(getNationalities),
  getOccupations: callAPI<typeof getOccupations, GetOccupationsResponse>(getOccupations),
  sendAbsherForUpdatingMobile: callAPI<typeof sendAbsherForUpdatingMobile, {}>(sendAbsherForUpdatingMobile),
  verifyAbsherForUpdatingMobile: callAPI<typeof verifyAbsherForUpdatingMobile, {}>(verifyAbsherForUpdatingMobile),
  sendOtpForUpdatingMobile: callAPI<typeof sendOtpForUpdatingMobile, {}>(sendOtpForUpdatingMobile),
  verifyOtpForUpdatingMobile: callAPI<typeof verifyOtpForUpdatingMobile, {}>(verifyOtpForUpdatingMobile),
  getVisaInvoiceFile: callAPI<typeof getVisaInvoiceFile, getInvoiceFileResponse>(getVisaInvoiceFile),
  getLaborOffices: callAPI<typeof getLaborOffices, GetLaborOfficesResponse>(getLaborOffices),
  getArrivalCities: callAPI<typeof getArrivalCities, GetArrivalCitiesResponse>(getArrivalCities),
  getUserProfile: callAPI<typeof getUserProfile, GetUserProfileResponse>(getUserProfile),
  getDefaultArrivalCity: callAPI<typeof getDefaultArrivalCity, GetDefaultArrivalCityResponse>(getDefaultArrivalCity),
  getWorkersList: callAPI<typeof getWorkersList, GetWorkersListResponse>(getWorkersList),
  getLocationDetails: callAPI<typeof getLocationDetails, GetLocationDetailsResponse>(getLocationDetails),
  updateUserPersonalInfo: callAPI<typeof updateUserPersonalInfo, {}>(updateUserPersonalInfo),
  updateUserContacts: callAPI<typeof updateUserContacts, {}>(updateUserContacts),
  changePassword: callAPI<typeof changePassword, {}>(changePassword),
  getVisaIssuePlaces: callAPI<typeof getVisaIssuePlaces, GetVisaIssuePlacesResponse>(getVisaIssuePlaces),
  getEligibleWorkers: callAPI<typeof getEligibleWorkers, GetEligibleWorkersResponse>(getEligibleWorkers),
  verifyAgent: callAPI<typeof verifyAgent, VerifyAgentResponse>(verifyAgent),
  createAgent: callAPI<typeof createAgent, {}>(createAgent),
  removeAgent: callAPI<typeof removeAgent, {}>(removeAgent),
  getReplaceableVisa: callAPI<typeof getReplaceableVisa, GetReplaceableVisaResponse, GeneralFailResponse>(
    getReplaceableVisa,
  ),
  getSkills: callAPI<typeof getSkills, GetSkillsResponse>(getSkills),
  getRejectionReasonsList: callAPI<typeof getRejectionReasonsList, RejectionReasonsListResponse>(
    getRejectionReasonsList,
  ),
  cancelVisa: callAPI<typeof cancelVisa, CancelVisaResponse, GeneralFailResponse>(cancelVisa),
  makeRefundSADAD: callAPI<typeof makeRefundSADAD, MakeRefundSADADResponse>(makeRefundSADAD),
  makeRefundPayFort: callAPI<typeof makeRefundPayFort, MakeRefundPayFortResponse>(makeRefundPayFort),
  getRefundableBills: callAPI<typeof getRefundableBills, GetBillsResponse>(getRefundableBills),
  checkOutdatedIBAN: callAPI<typeof checkOutdatedIBAN, CheckOutdatedIBANResponse>(checkOutdatedIBAN),
  getDownloadHistory: callAPI<typeof getDownloadHistory, DownloadHistoryResponse>(getDownloadHistory),
  getDownloadHistoryDetails: callAPI<typeof getDownloadHistoryDetails, DownloadHistoryDetailsResponse>(
    getDownloadHistoryDetails,
  ),
  getLabors: callAPI<typeof getLabors, GetLaborsResponse>(getLabors),
  updateLabors: callAPI<typeof updateLabors, UpdateLaborsResponse>(updateLabors),
  keepAliveEvisaToken: callAPI<typeof keepAliveEvisaToken, KeepAliveEvisaTokenResponse>(keepAliveEvisaToken),
  submitWataniSurvey: callAPI<typeof submitWataniSurvey, SubmitSurveyResponse>(submitWataniSurvey),
  getOccupationDescriptions: callAPI<typeof getOccupationDescriptions, GetOccupationDescriptionsResponse>(
    getOccupationDescriptions,
  ),
  surveySubmit: callAPI<typeof surveySubmit, {}>(surveySubmit),
  getRunaways: callAPI<typeof getRunaways, GetRunawaysResponse>(getRunaways),
  reportRunawayLaborer: callAPI<typeof reportRunawayLaborer, GetRunawaysResponse>(reportRunawayLaborer),
  getEligibleLaborers: callAPI<typeof getEligibleLaborers, GetEligibleLaborersResponse>(getEligibleLaborers),
  checkFeesExemption: callAPI<typeof checkFeesExemption, CheckFeesExemptionResponse>(checkFeesExemption),
  submitFeesExemption: callAPI<typeof submitFeesExemption, {}>(submitFeesExemption),
  uploadDisabilityProof: callAPI<typeof uploadDisabilityProof, UploadDisabilityProofResponse>(uploadDisabilityProof),
  cancelReportRunawayLaborer: callAPI<typeof cancelReportRunawayLaborer, GetRunawaysResponse>(
    cancelReportRunawayLaborer,
  ),
  removeFromPilotList: callAPI<typeof removeFromPilotList, {}>(removeFromPilotList),
  updateNationalAddress: callAPI<typeof updateNationalAddress, {}>(updateNationalAddress),
  setNewPrimaryAddress: callAPI<typeof setNewPrimaryAddress, {}>(setNewPrimaryAddress),
}
