import authManager from 'common/utils/auth/authManager'
import type {
  LoginBody,
  VerifyBody,
  ResendBody,
  MobileCheckBody,
  MobileVerifyBody,
  RegVerifyBody,
  CheckIdBody,
  RequestResetBody,
  VerifyResetBody,
  ResetPasswordBody,
  LoginNafathBody,
  CheckNafathBody,
  ActAsPremiumBody,
} from 'api/evisaAPI/auth'
import { history } from 'redux/browserHistory'
import { Routes } from 'containers/Routes/routePaths'

import Actions from '../../actions'
import { resetReducerState } from '../resetReducerState'

export const login = (params: LoginBody) => ({
  type: Actions.LOGIN,
  payload: { params },
})

export const loginNafath = (params: LoginNafathBody) => ({
  type: Actions.LOGIN_NAFATH,
  payload: { params },
})

export const checkNafath = (params: CheckNafathBody) => ({
  type: Actions.CHECK_NAFATH,
  payload: { params },
})

export const actAsPremium = (params: ActAsPremiumBody) => ({
  type: Actions.ACT_AS_PREMIUM,
  payload: { params },
})

export const logOut = (autoLoggedOut = false, isLaborerPortal = false) => {
  authManager.removeData()
  history.replace(isLaborerPortal ? Routes.LOGIN_LABORER : Routes.LOGIN, { autoLoggedOut })

  return resetReducerState()
}

export type UpdateAuthParams = {
  loggedIn?: boolean
  logOutWarning?: boolean
  userProfile?: ReturnType<typeof authManager.getUserProfileData>
}
export const updateAuth = (params: UpdateAuthParams) => ({
  type: Actions.UPDATE_AUTH,
  payload: params,
})

export const verify = (params: VerifyBody) => ({
  type: Actions.VERIFY,
  payload: { params },
})

export const resend = (params: ResendBody) => ({
  type: Actions.RESEND,
  payload: { params },
})

export const checkMobile = (params: MobileCheckBody) => ({
  type: Actions.MOBILE_CHECK,
  payload: { params },
})

export const verifyMobile = (params: MobileVerifyBody) => ({
  type: Actions.MOBILE_VERIFY,
  payload: { params },
})

export const regVerify = (params: RegVerifyBody) => ({
  type: Actions.REG_VERIFY,
  payload: { params },
})

export const checkId = (params: CheckIdBody) => ({
  type: Actions.CHECK_ID,
  payload: { params },
})

export const requestReset = (params: RequestResetBody) => ({
  type: Actions.REQUEST_RESET,
  payload: { params },
})

export const verifyReset = (params: VerifyResetBody) => ({
  type: Actions.VERIFY_RESET,
  payload: { params },
})

export const resetPassword = (params: ResetPasswordBody) => ({
  type: Actions.RESET_PASSWORD,
  payload: { params },
})

export const resetToken = () => ({
  type: Actions.RESET_TOKEN,
})

export type ResetTokenAction = {
  type: Actions.RESET_TOKEN
}

export const setSignUpStep = (step: number) => ({
  type: Actions.SET_SIGN_UP_STEP,
  payload: step,
})

export type SetSignUpStepAction = {
  type: Actions.SET_SIGN_UP_STEP
  payload: number
}

export const getAuthData = () => ({
  type: Actions.GET_AUTH_DATA,
})

export const keepAlive = () => ({
  type: Actions.KEEP_ALIVE,
})

export const checkCaptcha = () => ({
  type: Actions.CHECK_CAPTCHA,
})

export const getUserData = () => ({
  type: Actions.GET_USER_DATA,
})

export const resetCompleted = () => ({
  type: Actions.RESET_COMPLETED_STATE,
})

export type ResetCompletedAction = {
  type: Actions.RESET_COMPLETED_STATE
}
