import { CallAPIAction, Status } from 'api/types'
import type {
  AuthHeader,
  LoginResponse,
  LoginFailResponse,
  CheckIdResponse,
  CheckIdFailResponse,
  VerifyResponse,
  VerifyFailResponse,
  RegVerifyResponse,
  RegVerifyFailResponse,
  MobileVerifyResponse,
  MobileVerifyFailResponse,
  RequestResetResponse,
  RequestResetFailResponse,
  VerifyResetResponse,
  VerifyResetFailResponse,
  ResetPasswordResponse,
  ResetPasswordFailResponse,
  ActAsPremiumResponse,
} from 'api/evisaAPI/auth'
import type { AuthDataResponseFail } from 'saga/sagas/evisaAPI/authSaga'
import { EVISA_AUTH_HEADER } from 'common/constants'

import Actions from '../actions'
import type { ResetTokenAction, SetSignUpStepAction, ResetCompletedAction } from '../actionCreators/evisaAPI/auth'

export enum SignUpSteps {
  ID,
  ABSHIR,
  COMPLETE,
  PHONE_VERIFY,
  PASSWORD,
}

export enum ResetSteps {
  ID,
  ABSHIR,
  PASSWORD,
}

type State = {
  errorCode: number | null
  signUpStep: SignUpSteps
  resetStep: ResetSteps
  errorMessage: string
  emailError: Array<string> | null
  token: string
  verified: boolean
  termsAccepted: boolean
  registrationToken: string
  completed: boolean
  resetCompleted: boolean
  resetToken: string
  loggedInSuccessfully: boolean
  isAgent?: boolean
  premiumToken?: string
  agentFor?: {
    name?: string
    idNumber?: number
  }
} & Status

const initialState = {
  errorCode: null,
  errorMessage: '',
  isAgent: false,
  premiumToken: '',
  signUpStep: SignUpSteps.ID,
  resetStep: ResetSteps.ID,
  token: '',
  registrationToken: '',
  verified: false,
  termsAccepted: false,
  completed: false,
  emailError: null,
  resetCompleted: false,
  resetToken: '',
  loggedInSuccessfully: false,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action:
    | CallAPIAction<
        Actions.LOGIN_ASYNC,
        Actions.LOGIN_SUCCESS,
        Actions.LOGIN_FAIL,
        LoginResponse,
        LoginFailResponse,
        AuthHeader
      >
    | CallAPIAction<
        Actions.ACT_AS_PREMIUM_ASYNC,
        Actions.ACT_AS_PREMIUM_SUCCESS,
        Actions.ACT_AS_PREMIUM_FAIL,
        ActAsPremiumResponse,
        LoginFailResponse,
        AuthHeader
      >
    | CallAPIAction<
        Actions.CHECK_ID_ASYNC,
        Actions.CHECK_ID_SUCCESS,
        Actions.CHECK_ID_FAIL,
        CheckIdResponse,
        CheckIdFailResponse,
        AuthHeader
      >
    | CallAPIAction<
        Actions.VERIFY_ASYNC,
        Actions.VERIFY_SUCCESS,
        Actions.VERIFY_FAIL,
        VerifyResponse,
        VerifyFailResponse
      >
    | CallAPIAction<
        Actions.REG_VERIFY_ASYNC,
        Actions.REG_VERIFY_SUCCESS,
        Actions.REG_VERIFY_FAIL,
        RegVerifyResponse,
        RegVerifyFailResponse,
        AuthHeader
      >
    | CallAPIAction<
        Actions.MOBILE_VERIFY_ASYNC,
        Actions.MOBILE_VERIFY_SUCCESS,
        Actions.MOBILE_VERIFY_FAIL,
        MobileVerifyResponse,
        MobileVerifyFailResponse
      >
    | CallAPIAction<
        Actions.REQUEST_RESET_ASYNC,
        Actions.REQUEST_RESET_SUCCESS,
        Actions.REQUEST_RESET_FAIL,
        RequestResetResponse,
        RequestResetFailResponse
      >
    | CallAPIAction<
        Actions.VERIFY_RESET_ASYNC,
        Actions.VERIFY_RESET_SUCCESS,
        Actions.VERIFY_RESET_FAIL,
        VerifyResetResponse,
        VerifyResetFailResponse
      >
    | CallAPIAction<
        Actions.RESET_PASSWORD_ASYNC,
        Actions.RESET_PASSWORD_SUCCESS,
        Actions.RESET_PASSWORD_FAIL,
        ResetPasswordResponse,
        ResetPasswordFailResponse
      >
    | CallAPIAction<
        Actions.GET_AUTH_DATA_ASYNC,
        Actions.GET_AUTH_DATA_SUCCESS,
        Actions.GET_AUTH_DATA_FAIL,
        {},
        AuthDataResponseFail
      >
    | CallAPIAction<
        Actions.GET_USER_DATA_ASYNC,
        Actions.GET_USER_DATA_SUCCESS,
        Actions.GET_USER_DATA_FAIL,
        {},
        AuthDataResponseFail
      >
    | ResetTokenAction
    | SetSignUpStepAction
    | ResetCompletedAction,
): State => {
  switch (action.type) {
    case Actions.VERIFY_ASYNC:
    case Actions.REG_VERIFY_ASYNC:
    case Actions.MOBILE_VERIFY_ASYNC:
    case Actions.VERIFY_RESET_ASYNC:
    case Actions.REQUEST_RESET_ASYNC:
    case Actions.RESET_PASSWORD_ASYNC:
    case Actions.CHECK_ID_ASYNC:
      return {
        ...state,
        ...action.status,
        errorCode: null,
        errorMessage: '',
      }
    case Actions.LOGIN_ASYNC:
      return {
        ...state,
        ...action.status,
        errorCode: null,
        errorMessage: '',
        verified: false,
      }

    case Actions.ACT_AS_PREMIUM_SUCCESS: {
      return {
        ...state,
        ...action.status,
        premiumToken: action.payload.token,
      }
    }
    case Actions.LOGIN_SUCCESS: {
      const token = action.headers[EVISA_AUTH_HEADER]
      const { verified } = action.payload

      return {
        ...state,
        ...action.status,
        termsAccepted: action.payload.terms_accepted,
        verified,
        token,
        errorCode: null,
      }
    }
    case Actions.VERIFY_SUCCESS:
      return {
        ...state,
        ...action.status,
        verified: true,
        isAgent: action.payload.is_agent,
        agentFor: {
          name: action.payload.for?.name,
          idNumber: action.payload.for?.id_number,
        },
      }
    case Actions.LOGIN_FAIL:
    case Actions.VERIFY_RESET_FAIL:
    case Actions.REQUEST_RESET_FAIL:
    case Actions.RESET_PASSWORD_FAIL:
    case Actions.MOBILE_VERIFY_FAIL:
    case Actions.REG_VERIFY_FAIL:
    case Actions.CHECK_ID_FAIL:
    case Actions.VERIFY_FAIL: {
      const { code = null, message = '' } = action.payload?.error || {}
      return {
        ...state,
        ...action.status,
        errorCode: code,
        errorMessage: message,
      }
    }
    case Actions.REG_VERIFY_SUCCESS: {
      const token = action.headers[EVISA_AUTH_HEADER]

      const { payload } = action.payload
      return {
        ...state,
        ...action.status,
        registrationToken: payload,
        verified: true,
        token,
      }
    }
    case Actions.MOBILE_VERIFY_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CHECK_ID_SUCCESS: {
      const { payload } = action.payload
      return {
        ...state,
        ...action.status,
        signUpStep: SignUpSteps.ABSHIR,
        registrationToken: payload,
      }
    }
    case Actions.REQUEST_RESET_SUCCESS: {
      return {
        ...state,
        ...action.status,
        resetStep: ResetSteps.ABSHIR,
      }
    }
    case Actions.VERIFY_RESET_SUCCESS: {
      return {
        ...state,
        ...action.status,
        resetStep: ResetSteps.PASSWORD,
        resetToken: action.payload.token || '',
      }
    }
    case Actions.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        ...action.status,
        resetCompleted: true,
      }
    }
    case Actions.RESET_TOKEN:
      return {
        ...state,
        token: '',
      }
    case Actions.SET_SIGN_UP_STEP:
      return {
        ...state,
        signUpStep: action.payload,
      }
    case Actions.GET_AUTH_DATA_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.GET_USER_DATA_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.GET_AUTH_DATA_SUCCESS:
      return {
        ...state,
        loggedInSuccessfully: true,
        signUpStep: SignUpSteps.COMPLETE,
        ...action.status,
      }
    case Actions.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        completed: true,
        ...action.status,
      }
    case Actions.GET_AUTH_DATA_FAIL:
      return {
        ...state,
        errorMessage: action.payload!.errorMessage,
        verified: false,
        loggedInSuccessfully: false,
        ...action.status,
      }
    case Actions.RESET_COMPLETED_STATE:
      return {
        ...state,
        completed: false,
      }
    case Actions.GET_USER_DATA_FAIL:
      return {
        ...state,
        errorMessage: action.payload!.errorMessage,
        completed: true,
        ...action.status,
      }
    default:
      return state
  }
}
